<template>
  <div class="wrap">

  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data() {
      return {
        loading: null,
        code: "",
        state: ""
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.code = this.$route.query.code;
        this.state = this.$route.query.state;
        this.loading = this.$loading({
          lock: true,
          text: this.state == 2 ? '正在绑定，请稍等！' :'正在登录，请稍等！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        // 通过state的值判断登录还是绑定
        if(this.state == 2){
          // 绑定
          this.wechatBind();
        }else{
          // 登录
          this.wechatLogin();
        }
        
      },
      // 微信登录
      wechatLogin() {
        this.$api.wechatLogin({
          code: this.code
        }).then(res => {
          if (res.status == 100) {
            // 用户登录成功
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role_id", res.data.user.role_id);
            localStorage.setItem("name", res.data.user.name);
            localStorage.setItem("cityname", res.data.user.city_name);
            localStorage.setItem("city", res.data.user.city_id);
            localStorage.setItem("outTime", res.data.user.brand_end_date);
            localStorage.setItem("phone", res.data.user.phone);
            localStorage.setItem("vipId", res.data.vipId);
            localStorage.setItem("userAvatar", res.data.user.head_photo);
            localStorage.setItem("userId", res.data.user.user_id);
            sessionStorage.setItem("phone", res.data.user.phone);

            let role_id = res.data.user.roleId;
            if (
              role_id == "38e045588e0e4ce19b80ca40f5325934" ||
              role_id == "de9de2f006e145a29d52dfadda295353" ||
              role_id == "4"
            ) {
              this.checkboxSet(res.data.token);
            } else {
              this.loginJump();
            }
          }else if(res.status == 101){
            // 用户未注册或者用户已注册，未绑定微信
            this.loading.close();
            this.$alert('您未绑定微信，请先登录系统进入个人中心账户安全页面绑定微信。', {
              confirmButtonText: '确定',
              callback: () => {
                dump.link({
                  type: 1,
                  link: "/login"
                })
              }
            });
          }
        }).catch(err => console.error(err))
      },
      //设置价格开关
      checkboxSet(token) {
        const that = this;
        that.$api.checkboxSet({
          priceChange: "1",
          token: token
        }).then((res) => {
          if (res.status == 100) {
            that.loginJump();
          }
        });
      },
      //跳转
      loginJump() {
        // const that = this;
        // if (that.$route.query.id == 1 || that.pathUrl == '/') {
        // 	setTimeout(() => {
        // 		dump.link({
        // 			type: 1,
        // 			link: "/",
        // 		});
        // 		that.logInTo = false;
        // 	}, 1000)
        // } else {
        // 	setTimeout(() => {
        // 		that.$router.back();
        // 		that.logInTo = false;
        // 	}, 1000)
        // }
        this.loading.close();

        dump.link({
          type: 1,
          link: "/",
        });
      },
      // 绑定微信
      wechatBind(){
        this.$api.wechatBind({
          code: this.code,
          userId: localStorage.getItem("userId")
        }).then(res => {
          if(res.status == 100){
            this.loading.close();
            this.$message({
              message: '绑定成功',
              type: 'success'
            });
            setTimeout(() => {
              dump.link({
                type: 1,
                link: "/usercenter/accountSecurity",
              });
            },1000)
          }
        }).catch(err => console.error(err))
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
